import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, IPageWillMountHandler, IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { ActivePopupConfig } from './types'
import { name } from './symbols'
import { PopupsSymbol, IPopups } from 'feature-popups'
import { updateCurrentPopup } from './activePopupUtils'

const activePopupFactory = (
	pageConfig: ActivePopupConfig,
	propsStore: IPropsStore,
	popupsAPI?: IPopups
): IPageWillMountHandler => {
	return {
		async pageWillMount() {
			if (popupsAPI) {
				popupsAPI.registerToPopupEvent('popupOpen', (popupPageId: string) => {
					propsStore.update(updateCurrentPopup(pageConfig.activePopupObservers, popupPageId))
				})
				popupsAPI.registerToPopupEvent('popupClose', () => {
					propsStore.update(updateCurrentPopup(pageConfig.activePopupObservers))
				})
			}
		},
	}
}

export const ActivePopup = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, optional(PopupsSymbol)],
	activePopupFactory
)
